import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { connect } from 'react-redux'
import styles from '../styles/navbar.module.css'


const Example = ({ lang }) => {
    return (
        <ListGroup>

            <ListGroupItem style={{ borderRadius: '10px', borderStyle: 'solid', borderColor: '#d4af37', borderWidth: '5px', backgroundColor: '#FFFEF2' }}>

                <div className='mt-3' style={{ padding: '20px' }}>

                    <img alt='airplane' height='40px' width='auto' src="https://lh3.googleusercontent.com/aASaEnpBRqZfIhnCVNtLTX9gfK3TAu-7RW8_arCvZ8p4cR95ovYoC2tu8rFsSqUFxDq7lMg9VBbgZJUg4Bprg1P20z6_tMXlWKbGhdQh9-A6buKC-4Vubs4owO8W1w0lg2iGcz0cOc0=w144-h178-no" />

                    <h1 style={{ color: '#d4af37' }} >{lang === 'english' ? "México City to Villahermosa" : "La Cuidad de México a Villahermosa"}</h1>

                    <p style={{ color: '#d4af37' }} className='mt-4'>MEX {lang === 'english' ? 'to' : 'a'} VSA</p>

                    <p style={{ fontSize: '1.2em', color: '#d4af37' }}>{lang === 'english' ? 'México City is the most common layover for flights going to Villahermosa from the US. Remember to allow at least 2 hours in Mexico City to go thru customs between flights. Depending on your situation, an alternate route might be better for the return flight. Southwest flys out of Cancún.' : 'La ciudad de México es la escala más común para los vuelos que van a Villahermosa desde los EU. Recuerde permitir al menos 2 horas en la Ciudad de México para pasar por la aduana entre vuelos. Dependiendo de su situación, una ruta alternativa podría ser mejor para el vuelo de regreso. Southwest vuela desde Cancún.'}</p>

                    <p style={{ fontSize: '1em', color: '#d4af37', fontWeight: 'bold', fontStyle: 'italic' }}>{lang === 'english' ?
                        "Google Flights does not show all flights to Mexico City or Villahermosa."
                        : "Google Vuelos no muestra todos los opciones a la Cuidad de México o Villahermosa."}</p>

                    <p style={{ fontSize: '1em', color: '#d4af37', fontWeight: 'bold', fontStyle: 'italic' }}>{lang === 'english' ?
                        "Please check directly with:"
                        : "Por favor checalo directo en:"}</p>

                    <p className={styles.listGroup} style={{ color: '#d4af37' }}>
                        <a rel="noopener noreferrer" href='https://www.aa.com/homePage.do' target='_blank'>American</a> • <a rel="noopener noreferrer" href='https://www.delta.com/' target='_blank'>Delta</a> • <a rel="noopener noreferrer" href='https://www.interjet.com/es-mx' target='_blank'>Interjet</a> • <a rel="noopener noreferrer" href='https://www.united.com/en/us' target='_blank'>United</a> • <a rel="noopener noreferrer" href='https://www.vivaaerobus.com/en' target='_blank'>Vivaaerobus</a> • <a rel="noopener noreferrer" href='https://www.volaris.com/?culture=en-US&currency=USD' target='_blank'>Volaris</a>
                    </p>
                </div>
            </ListGroupItem>
        </ListGroup>
    );
}

export default connect(state => ({
    lang: state.lang
}), null)(Example)