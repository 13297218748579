import React from 'react';
import { Card, CardImg, CardTitle, CardGroup, CardBody } from 'reactstrap';
import { connect } from 'react-redux'


const Example = ({ lang }) => {
    return (
        <CardGroup>
            <Card body inverse style={{ backgroundColor: '#006341' }}>
                <CardImg top width="100%" src="https://www.hotelviva.com.mx/cache/ad/2a/ad2a5c47e6aaf0a5f56365006e9bfa15.png" alt="Card image cap" />
                <CardBody>
                    <CardTitle><h3>Hotel Viva Villahermosa ★</h3></CardTitle>
                    {lang === 'english' ? (<ul style={{ listStyle: 'none', marginLeft: '0', textAlign: 'left' }}>
                        <li>Address: Boulevard Adolfo Ruiz Cortines, Esquina Paseo Tabasco s/n</li>
                        <li>Phone: 993 313 6000</li>
                        <li>WhatsApp: 5513895011 y 5579156848</li>
                        <li>Email: reservahv@hotelviva.com.mx</li>
                        <li>Website: <a rel="noopener noreferrer" href='https://www.hotelviva.com.mx' target='_blank'>https://www.hotelviva.com.mx</a></li>
                    </ul>) : (<ul style={{ listStyle: 'none', marginLeft: '0', textAlign: 'left' }}>
                        <li>Dirección: Boulevard Adolfo Ruiz Cortines, Esquina Paseo Tabasco s/n</li>
                        <li>Teléfono: 993 313 6000</li>
                        <li>WhatsApp: 5513895011 y 5579156848</li>
                        <li>Correo electrónico: reservahv@hotelviva.com.mx</li>
                        <li>Sitio web: <a rel="noopener noreferrer" href='https://www.hotelviva.com.mx' target='_blank'>https://www.hotelviva.com.mx</a></li>
                    </ul>)}
                </CardBody>
            </Card>
            <Card body style={{ backgroundColor: '#FFFFFF', color: '#000000' }}>
                <CardImg top width="100%" src='https://d25q089iqpcdqt.cloudfront.net/galleries/media/medlib/imageRepo/3/0/73/464/321/mural_J.jpg' alt="Card image cap" />
                <CardBody>
                    <CardTitle><h3>Fiesta Inn Villahermosa</h3></CardTitle>
                    {lang === 'english' ? (<ul style={{ listStyle: 'none', marginLeft: '0', textAlign: 'left' }}>
                        <li>Address: Benito Juárez García # 105 Col. Loma Linda 86050 Villahermosa Tabasco, México Mexico</li>
                        <li>Phone: (993) 313 66 11</li>
                        <li>Website: <a rel="noopener noreferrer" href='https://www.fiestainn.com/web/fiesta-inn-villahermosa-cencali' target='_blank'>https://www.caminoreal.com/hotel/quinta-real-villahermosa</a></li>
                    </ul>) : (<ul style={{ listStyle: 'none', marginLeft: '0', textAlign: 'left' }}>
                        <li>Dirección: Benito Juárez García # 105 Col. Loma Linda 86050 Villahermosa Tabasco, México Mexico</li>
                        <li>Teléfono: (993) 313 66 11</li>
                        <li>Sitio web: <a rel="noopener noreferrer" href='https://www.fiestainn.com/web/fiesta-inn-villahermosa-cencali' target='_blank'>https://www.caminoreal.com/hotel/quinta-real-villahermosa</a></li>
                    </ul>)}
                </CardBody>
            </Card>
            <Card body inverse style={{ backgroundColor: '#CE1126' }}>
                <CardImg top width="100%" src='https://assets.hyatt.com/content/dam/hyatt/hyattdam/images/2014/09/21/1359/VILLA-P031-Lobby.jpg/VILLA-P031-Lobby.16x9.jpg?imwidth=1280' alt="Hyatt lobby" />
                <CardBody>
                    <CardTitle><h3>Hyatt Regency Villahermosa</h3></CardTitle>
                    {lang === 'english' ?
                        <ul style={{ listStyle: 'none', marginLeft: '0', textAlign: 'left' }}>
                            <li>Address: Ave. Juárez 106, Col. Lindavista, Villahermosa, Mexico, 86050</li>
                            <li>Phone: 52 993 310 1234</li>
                            <li>Email: villahermosa.regency@hyatt.com</li>
                            <li>Website: <a rel="noopener noreferrer" href='https://www.hyatt.com/en-US/hotel/mexico/hyatt-regency-villahermosa/villa' target='_blank'>https://www.hyatt.com/en-US/hotel/mexico/hyatt-regency-villahermosa/villa</a></li>
                        </ul> :
                        <ul style={{ listStyle: 'none', marginLeft: '0', textAlign: 'left' }}>
                            <li>Dirección: Ave. Juárez 106, Col. Lindavista, Villahermosa, Mexico, 86050</li>
                            <li>Teléfono: 52 993 310 1234</li>
                            <li>Correo electrónico: villahermosa.regency@hyatt.com</li>
                            <li>Sitio web: <a rel="noopener noreferrer" href='https://www.hyatt.com/es-ES/hotel/mexico/hyatt-regency-villahermosa/villa' target='_blank'>https://www.hyatt.com/es-ES/hotel/mexico/hyatt-regency-villahermosa/villa</a></li>
                        </ul>}
                </CardBody>
            </Card>
        </CardGroup>
    );
};

export default connect(state => ({
    lang: state.lang
}), null)(Example)