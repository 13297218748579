import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';

const Example = (props) => {
    return (
        <ListGroup className='mt-4' flush style={{ textAlign: 'left', margin: 'auto', fontFamily: "'Dancing Script', cursive", fontSize: '1.4em', maxWidth: '400px' }}>
            <ListGroupItem style={{ backgroundColor: '#FFFEF2' }} >8 • Drinks, Appetizers</ListGroupItem>
            <ListGroupItem style={{ backgroundColor: '#FFFEF2' }}>9 • Dinner</ListGroupItem>
            <ListGroupItem style={{ backgroundColor: '#FFFEF2' }}>9:30 • Toasts, Dessert, and Dancing</ListGroupItem>
        </ListGroup>
    );

}

export default Example;