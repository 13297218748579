import React from 'react'
import { Button, Form, FormGroup, Input, InputGroupAddon, InputGroup } from 'reactstrap'
import { connect } from 'react-redux'
import { Spinner } from 'reactstrap';
import hidep from '../images/hidep.png'
import showp from '../images/pword.png'
import styles from '../styles/navbar.module.css'


//cuts 
// style={{ background: `${show ? hidep : showp}` }}

class RSVP extends React.Component {

    state = {
        form: {
            passcode: '',
            going: 'yes',
            number: 1,
            first: '',
            last: '',
            anames: '',
            email: '',
            message: ''
        },
        send: {
            sent: 0,
            err: '',
            success: '',
            loading: false,
        },
        show: false

    }

    startTimer = () => {
        setTimeout(() => {
            this.setState(prevState => ({
                form: {
                    ...prevState.form,
                    passcode: '',
                    going: 'yes',
                    number: 1,
                    first: '',
                    last: '',
                    anames: '',
                    email: '',
                    message: ''
                }
            }))
        }, 5 * 60 * 1000)
    }

    stopTimer = () => {
        clearTimeout(this.startTimer)
    }

    handleShow = e => {
        e.preventDefault()
        this.setState(prevState => ({
            ...prevState.send,
            show: !this.state.show
        }
        ))
    }

    handleChange = e => {
        this.stopTimer()
        this.startTimer()
        const value = e.target.value
        const name = e.target.name
        this.setState(prevState => ({
            form: {
                ...prevState.form,
                [name]: value
            }
        }))
    }

    handleSubmit = e => {
        this.setState(prevState => ({
            send: {
                ...prevState.send,
                loading: true
            }
        }))
        e.preventDefault()
        const rsvpApi = 'https://gentle-chamber-75846.herokuapp.com/site/rsvp'
        const { form } = this.state
        const rsvpPkg = { ...form }
        rsvpPkg['lang'] = this.props.lang
        fetch(rsvpApi, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${form.passcode}`
            },
            body: JSON.stringify(rsvpPkg)
        }).then(res => {
            if (res.status === 200) {
                return res.json()
            } else {
                this.setState(prevState => ({
                    send: {
                        ...prevState.send,
                        sent: prevState.send.sent + 1,
                        err: `Connection error (${res.StatusText})`,
                        loading: false
                    }
                }))
            }
        }).then(res => {
            const r = res.Response
            if (r.includes('No')) {
                this.setState(prevState => ({
                    send: {
                        ...prevState.send,
                        err: this.props.lang === 'english' ? 'Access denied. Please enter correct password.' :
                            'Acceso denegado. Por favor envíe la contraseña correcta',
                        sent: prevState.send.sent + 1,
                        success: '',
                        loading: false
                    }
                }))
            } else {
                this.setState(prevState => ({
                    form: {
                        passcode: '',
                        going: 'yes',
                        number: 1,
                        first: '',
                        last: '',
                        anames: '',
                        email: '',
                        message: ''
                    },
                    send: {
                        ...prevState.send,
                        success: this.props.lang === 'english' ? 'Thank you for RSVPing!' :
                            'Muchas gracias por confirmar asistencia!',
                        sent: 0,
                        err: '',
                        loading: false
                    }
                }))
            }
        }).catch(err => {
            this.setState(prevState => ({
                send: {
                    ...prevState.send,
                    err: `Connection error (${err.toString()})`,
                    success: ''
                }
            }))
        })
    }

    render() {
        const { lang } = this.props
        const { form, send, show } = this.state

        return (
            <Form className='mt-5' onSubmit={this.handleSubmit} style={{ maxWidth: '600px', margin: 'auto' }}>
                <FormGroup>
                    <InputGroup >
                        <Input autoComplete='off' value={form.passcode} onChange={this.handleChange} type={show ? 'text' : 'password'} name="passcode" id="passcode" placeholder={lang === 'english' ? 'Password (required)' : 'Contraseña (necesario)'} required />
                        <InputGroupAddon className={styles.showp} addonType="append">
                            <Button style={{ display: 'inline-block', backgroundColor: 'white', padding: '0px', margin: 'auto', fontSize: '1.4em', fontFamily: 'none' }} onClick={this.handleShow}><img style={{ marginBottom: '0px', verticalAlign: 'middle' }} height='30px' width='auto' alt={lang === 'english' ? "Show" : "Enseñar"} src={show ? hidep : showp}></img>
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <Input value={form.going} onChange={this.handleChange} type="select" name="going" id="going">
                        <option value='yes'>{lang === 'english' ? "Yes, we're going!" : 'Sí, vamos!'}</option>
                        <option value='no'>{lang === 'english' ? "No, we can't go." : 'No podemos ir.'}</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Input value={form.number} onChange={this.handleChange} type="select" name="number" id="number">
                        <option value={1}>1 {lang === 'english' ? "attendee" : 'asistente'} </option>
                        <option value={2}>2 {lang === 'english' ? "attendees" : 'asistentes'}</option>
                        <option value={3}>3 {lang === 'english' ? "attendees" : 'asistentes'}</option>
                        <option value={4}>4 {lang === 'english' ? "attendees" : 'asistentes'}</option>
                        <option value={5}>>4 {lang === 'english' ? "attendees" : 'asistentes'}</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Input value={form.first} onChange={this.handleChange} type="text" name="first" id="first" placeholder={lang === 'english' ? "First Name (required)" : 'Nombre (necesario)'} required />
                </FormGroup>
                <FormGroup>
                    <Input value={form.last} onChange={this.handleChange} type="text" name="last" id="last" placeholder={lang === 'english' ? "Last Name (required)" : 'Apellido (necesario)'} required />
                </FormGroup>
                {form.number > 1 ?
                    <div>
                        <FormGroup>
                            <Input value={form.anames} onChange={this.handleChange} type="textarea" name="anames" id="guest-name" placeholder={lang === 'english' ? "Names of the other attendees:" : 'Nombres de los otros asistentes:'} />
                        </FormGroup>
                    </div>
                    : <div></div>}

                <FormGroup>
                    <Input value={form.email} onChange={this.handleChange} type="email" name="email" id="email" placeholder={lang === 'english' ? "Email (optional)" : 'Correo electrónico (opcional)'} />
                </FormGroup>
                <FormGroup>
                    <Input value={form.message} onChange={this.handleChange} type="textarea" name="message" id="message" placeholder={lang === 'english' ? "Message (optional)" : 'Mensaje (opcional)'} />
                </FormGroup>
                <div style={{ marginBottom: '1em' }}>{send.loading ?
                    <div>
                        <p>{lang === 'english' ? "It can take a minute. Thank you for your patience." : "Puede tomar un minuto enviarlo. Gracias por su paciencia."}</p>
                        <p>{lang === 'english' ? "Loading..." : "Cargando..."}</p><Spinner />
                    </div> :
                    <div>
                        <p style={{ color: 'red' }}>{send.err}</p>
                        <p style={{ color: 'green' }}>{send.success}</p>
                    </div>}
                </div>
                <Button type='submit'>{lang === 'english' ? "Submit" : 'Enviar'}</Button>
            </Form>
        )
    }
}


export default connect(state => ({
    lang: state.lang
}), null)(RSVP)