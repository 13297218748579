import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import RSVP from '../components/rsvp-form'
import Cards from '../components/card-group'
import { Container, Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import styles from '../styles/layout.module.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Counter from '../components/countdown'
import ListGroup from '../components/list-group'
import Flights from '../components/flight-list-group'



const Wedding = ({ lang }) => (
  <Layout>
    <SEO title="Wedding" />
    <Container className='mt-4'>
      <Row>
        <Col className={styles.main} style={{ textAlign: 'center' }}>
          {lang === 'english' ?
            (<div>
              <img style={{ display: 'block', margin: 'auto' }} src='https://lh3.googleusercontent.com/UyOAt-S_ZMsqEY0yZ042BCXBjtDdo1IbG8tlJmN0O2CmcqV4d6FWT24Zeh9tMJD0Evq6V1Mh7_WRRO2WZQMobKKYcExdxWAgXd_-pzqwbtHKHsLB7u4fScLGdMX1-KK6C8siFzhC6QM=d' alt='invitation-english' />
              <p style={{ marginTop: '2em' }}>The wedding will be held in at Hotel Viva in Villahermosa, Tabasco, Mexico at 8PM.</p>
              <p>Contact information for Hotel Viva is listed below the RSVP along with a few other places to stay.</p>
              <h1 style={{ marginTop: '1em' }}>Schedule</h1>
              <ListGroup />
            </div>)
            : (<div style={{ textAlign: 'center' }}>
              <img style={{ display: 'block', margin: 'auto' }} src='https://lh3.googleusercontent.com/XTDqAQ1FNfPgyuJWY04-MM0HYC0v51v8g4uleKRQAGl9fckwrWR2YJVYPM6_J81D7EXM4FpH-9wIBfn1MRq7fg6Sm6hq9-9bDGlhyYQcj9EQGoTHWq-eW_3yaxJllMLN1sGv7CWeDwE=d' alt='invitation-español' />
              <p style={{ marginTop: '2em' }}>Se llevará a cabo en el Salón 1 del Hotel Viva, Villahermosa, Tabasco, México a las 8:00 pm.</p>
              <p>El código de vestimenta es etiqueta formal.</p>
            </div>)}
          <Counter />
          <p style={{ margin: 'auto' }}>{lang === 'english' ? 'Please RSVP by March 8th.' : 'Confirmar asistencia antes del 8 de Marzo.'}</p>
        </Col>
      </Row>
      <Row id='rsvp' className='mt-5'>
        <Col className={styles.main} style={{ textAlign: 'center' }}>
          <h1>{lang === 'english' ? 'RSVP' : 'Confirmar Asistencia'}</h1>
          <div className='mt-5'>
            <p>{lang === 'spanish' ? 'Favor de poner el número que te asignamos en el ticket o menor en caso que tú acompañante asignado no asista. En caso que acompañante designado no pueda ir, se elimina ese número, NO se sustituye.' : ''}</p>
          </div>
          <RSVP className={styles.showp} />
        </Col>
      </Row>
      <Row className='mt-5'>
        <Col className={styles.main} style={{ textAlign: 'center' }}>
          <h1>{lang === 'english' ? 'Hotels' : 'Hoteles'}</h1>
          <div className='mt-5'>
            <p>{lang === 'english' ? 'Hotel Viva offers a special rate to our guests! Please contact us for booking.' : 'Si gustas hospedarte en el Hotel Viva, tenemos una tarifa especial con descuento para ti! Informe al hotel que pertenece a nuestro grupo.'}</p>
          </div>
          <div className='mt-5'>
            <Cards />
          </div>
        </Col>
      </Row>
      <Row className='mt-5'>
        <Col className={styles.main} style={{ textAlign: 'center' }}>
          <h1>{lang === 'english' ? 'Flights' : 'Vuelos'}</h1>
          <div className='mt-5'>
            <p>{lang === 'english' ? 'Villahermosa has non-stop flights to Cancún, Mérida, México City, Monterrey, and Tampico. ' : ''}</p>
          </div>
          <div className='mt-5'>
            <Flights />
          </div>
        </Col>
      </Row>
    </Container>
  </Layout>
)


export default connect(state => ({
  lang: state.lang
}), null)(Wedding)