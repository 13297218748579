import React from 'react'
import Countdown from 'react-countdown'
import { connect } from 'react-redux'

const Counter = ({ lang }) => (
    <Countdown
        date={"2020-03-21T18:00:00"}
        renderer={props => <div style={{ textAlign: 'center', marginTop: '1.2em' }}>
            <p style={{ display: 'inline-block' }}>{lang === 'english' ? 'We are' : 'Faltan'}
                <span style={{ fontWeight: 'bold' }}> {props.days} </span>
                {lang === 'english' ? 'days' : 'dias'}
                <span style={{ fontWeight: 'bold' }}> {props.hours} </span>
                {lang === 'english' ? 'hours' : 'horas'}
                <span style={{ fontWeight: 'bold' }}> {props.minutes} </span>
                {lang === 'english' ? 'minutes and' : 'minutos y'}
                <span style={{ fontWeight: 'bold' }}> {props.seconds} </span>
                {lang === 'english' ? 'seconds away!' : 'segundos!'}
            </p>
        </div>}
    />
)


export default connect(state => ({
    lang: state.lang
}), null)(Counter)